//import './xrextras/custom-almost-there-module';
// import './xrextras/custom-runtime-error-module';
import './xrextras/custom-loading-module';
// import 'aframe-mouse-cursor-component';
// import OperationalSystem from './utils/OperationalSystem';
// import os from './utils/os'

// function detectmob() { 
//     if( navigator.userAgent.match(/Android/i)){  
//         new OperationalSystem(os.ANDROID).oSystem.init();  
//     } else if (navigator.userAgent.match(/iPhone/i)
//         || navigator.userAgent.match(/iPad/i)
//         || navigator.userAgent.match(/iPod/i)
//         ){
//         new OperationalSystem(os.IOS).oSystem.init();  
//     } else {
//         new OperationalSystem(os.ANDROID).oSystem.init();   
//     }
// }

window.onload = function(){     
    // detectmob();    
}