AFRAME.registerComponent('customize-loading-module', {
    init: function() {
        
        // Loading screen
        let loadImage = document.getElementById("loadImage");
        loadImage.src="./assets/dblab-loading.png";

        // Camera Permissions
        let htmlRequestCamera = "<img id='requestingCameraIcon' src='./assets/camera-white.png' />" +
            "Clique “Permitir” então você<br>aumentará sua realidade.";
        let requestingCameraPermissions =  document.getElementById("requestingCameraPermissions");
        requestingCameraPermissions.innerHTML = htmlRequestCamera;
        requestingCameraPermissions.style.backgroundColor =  "#4B51A6";

        // Permission error, iOS
        let htmlCameraPermissionsErrorApple = "Atualize a página para ativar <br>sua câmera para a experiência em Realidade Aumentada" +
            "<img id='cameraPermissionsErrorAppleArrow' src='./assets/arrow.png' />" +
            "<img id='cameraPermissionsErrorAppleReloadCamera' src='./assets/reload-camera.png' />";
        document.getElementById("cameraPermissionsErrorApple").innerHTML = htmlCameraPermissionsErrorApple;

        // Permission error, Android
        let htmlCameraPermissionsErrorAndroid = 
            "<div id='cameraPermissionsCameraIcon'>" + 
                "<img src='./assets/reload-camera.png' />" +
            "</div>" +
            "<div class='loading-error-header'>Vamos permitir sua câmera</div>" +
            "<ol class='loading-error-instructions'>" +
                "<li>Toque os <img src='./assets/ellipsis.png'> no topo a direita</li>" +
                "<li>Tap <span class='highlight'>Configurações</li>" +
                "<li class='chrome-instruction hidden'>" +
                    "<span class='highlight'>Configurações do site</span>" +
                "</li>" +
                "<li class='chrome-instruction hidden'>" + 
                    "<span class='highlight'>Câmera</span>" +
                "</li>" +
                "<li class='chrome-instruction hidden'>" + 
                    "<span class='highlight'>Bloqueado</span>" +
                    "<br>" +
                    "<span class='camera-instruction-block domain-view'>apps.8thwall.com</span>" +
                "</li>" +
                "<li class='chrome-instruction hidden'>" +
                    "<span class='camera-instruction-button'>LIMPAR & RESETAR</span>" +
                "</li>" +
                "<li class='samsung-instruction hidden'>" +
                    "<span class='highlight'>Avançado</span>" +
                "</li>" +
                "<li class='samsung-instruction hidden'>" +
                    "<span class='highlight'>Gerenciar dados do site</span>" +
                "</li>" +
                "<li class='samsung-instruction hidden'>" +
                    "Pressione e segure<br>" +
                    "<span class='camera-instruction-block domain-view'>apps.8thwall.com</span>" +
                "</li>" +
                "<li class='samsung-instruction hidden'>" +
                    "<span class='highlight' style='color: #1500ba'>DELETAR</span>" +
                "</li>" + 
            "</ol>" + 
            "<div class='loading-error-footer'>" +
                "<img src='//cdn.8thwall.com/web/img/loading/v1/reload.png' />" +
                "Então, recarregue a página para experienciar a Realidade Aumentada!" + 
            "</div>";
        document.getElementById("cameraPermissionsErrorAndroid").innerHTML = htmlCameraPermissionsErrorAndroid;
        
        // iOS devicemotion sensor is disabled
        let htmlDeviceMotionErrorApple = "<div class='loading-error-header'>Vamos habilitar seus sensores de movimento</div>" +
            "<ol class='loading-error-instructions'>" +
                "<li>Abra <img src='https://cdn.8thwall.com/web/img/loading/v1/settings-icon-ios.png' style='max-height: 4vh'><b>Ajustes</b></li>" +
                "<li>Selecione <img src='https://cdn.8thwall.com/web/img/loading/v1/safari-icon.png' style='max-height: 4vh'><b>Safari</b></li>" +
                "<li>Ative <span class='highlight'>Acesso de Movimento e Orientação</span></li>" +
                "<li>Recarregue a página <img src='//cdn.8thwall.com/web/img/loading/v1/reload.png'></li>" +
            "</ol>" +
            "<div class='loading-error-footer'></div>";
        document.getElementById("deviceMotionErrorApple").innerHTML = htmlDeviceMotionErrorApple;

        let htmlUserPromptError = "<h1>As permissões foram negadas.</h1>" +
            "<p>Você precisa permitir as permissões para continuar.</p>" +
            "<button id='reloadButton' class='main-button' onClick='window.location.reload()'>Recarregar</button>";
        document.getElementById("userPromptError").innerHTML = htmlUserPromptError;
        
        let htmlMotionPermissionsErrorApple = "<h1>As permissões foram negadas.</h1>" +
            "<p>Você negou a página o acesso aos seus sensores de movimento.</p>" +
            "<p>Por favor, feche o Safari e permita os seus sensores de movimento.</p>"; 
            document.getElementById("motionPermissionsErrorApple").innerHTML = htmlMotionPermissionsErrorApple;
    }
})